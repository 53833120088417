<template>
  <div :class="`app-layout ${classes}`" ref="appLayout">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
};
</script>

<style>
.app-layout {
  width: 100%;
  padding-bottom: 3.4375rem;
  background-color: var(--pink);
  text-align: left;
  min-width: 0;
  min-height: 100vh;
}

.app-layout.article {
  padding-top: 3.5rem;
  background-color: #fff;
}

@media (min-width: 640px) {
  .app-layout {
    max-width: var(--max-container-width);
    border-radius: 2rem;
    position: relative;
    margin: 2rem auto;
    padding-bottom: calc(3.4375rem + 1rem);
  }
}
</style>
