<template>
  <app-layout classes="milestone-complete">
    <div class="p-2 p-md-3" v-if="milestone">
      <div v-if="isMediaMilestone">
        <div class="milestone-media">
          <img :src="milestone.completed_milestone_media.url" alt="">
          <img class="milestone-media__confetti" src="../../assets/confetti.svg" alt="">
        </div>

        <div class="text-center">
          <h1 class="article__heading mb-2 mb-md-3">
            {{ $textInterpolate.interpolate(milestone.translation.title) }}
          </h1>

          <div
            class="mb-2"
            v-if="milestone.translation.completed_description"
            v-html="$textInterpolate.interpolate(milestone.translation.completed_description)"
          />

          <router-link class="btn btn--primary" :to="{ name: 'Pet' }">
            Ga verder
          </router-link>
        </div>
      </div>
      <div v-else>
        <img class="milestone-media__confetti-left" src="../../assets/confetti-left.svg" alt="">
        <img class="milestone-media__confetti-right" src="../../assets/confetti-right.svg" alt="">
        <img class="milestone-media__confetti-top" src="../../assets/confetti-top.svg" alt="">

        <div class="text-center">
          <img
            class="mb-3 mt-3"
            src="../../assets/badge.svg"
            alt=""
          />

          <div class="milestone__eyebrow mb-1">
            Goed bezig!
          </div>

          <h1 class="article__heading mb-2 mb-md-3">
            <span class="sr-only">Afgerond: </span> {{ milestone.translation.title }}
          </h1>

          <div
            class="milestone__content mb-3"
            v-if="milestone.translation.completed_description"
            v-html="$textInterpolate.interpolate(milestone.translation.completed_description)"
          />

          <div class="milestone__date mb-3">
            <label class="mb-1 d-block" for="completed_at">Datum van afronden</label>
            <input
              class="form-control"
              type="date"
              id="completed_at"
              :value="date"
              :max="dateMax"
              @change="onDateChange"
            >
          </div>

          <router-link class="btn btn--primary" :to="{ name: 'Pet' }">
            Ga verder
          </router-link>
        </div>
      </div>
    </div>
  </app-layout>
</template>

<script>
import { mapGetters } from 'vuex';
import AppLayout from '../AppLayout.vue';
import dateFormat from '../../helpers/dateFormat';

export default {
  inject: ['$meta'],

  components: {
    AppLayout,
  },

  data() {
    const defaultDate = dateFormat(new Date(), 'yyyy-MM-dd');
    let dateMax = new Date();
    dateMax.setDate(dateMax.getDate() + 1);
    dateMax = dateFormat(dateMax, 'yyyy-MM-dd');

    return {
      date: defaultDate,
      dateMax,
    };
  },

  created() {
    this.$store.dispatch('loadMilestones');
  },

  computed: {
    ...mapGetters([
      'milestones',
      'milestonesLoadingState',
    ]),

    isMediaMilestone() {
      return this.milestone ? this.milestone.completed_milestone_media : false;
    },

    milestone() {
      if (!this.milestones.length) {
        return null;
      }

      return this.milestones.find((milestone) => (
        milestone.translation.slug === this.$route.params.slug
      ));
    },
  },

  methods: {
    onBackClick() {
      this.$router.go(-1);
    },

    onDateChange(event) {
      // Format is Y-m-d
      const date = event.currentTarget.value;

      this.date = date;

      const formData = new FormData();
      formData.append('completed_at', date);

      this.$store.dispatch('updateMilestoneComplete', [
        this.milestone.id,
        formData,
      ]);
    },
  },
};
</script>

<style>
.article__heading {
  font-size: 1.3125rem;
  font-weight: 400;
  color: var(--purple);
}

.milestone__eyebrow {
  font-size: 1.3125rem;
  font-weight: 700;
  color: var(--purple);
}

.milestone-media {
  background: #fff;
  padding: .75rem;
  max-width: 20rem;
  margin: 0 auto 1.5rem;
  position: relative;
}

.milestone-media img {
  display: block;
  width: 100%;
}

.milestone-media .milestone-media__confetti {
  position: absolute;
  top: -7.5%;
  left: -7.5%;
  width: 115%;
}

.milestone__content {
  max-width: 28rem;
  margin-left: auto;
  margin-right: auto;
}

.milestone-media__confetti-left,
.milestone-media__confetti-right,
.milestone-media__confetti-top {
  position: absolute;
  display: block;
}

.milestone-media__confetti-left {
  right: 90%;
}

.milestone-media__confetti-right {
  left: 90%;
}

.milestone-media__confetti-top {
  top: -1rem;
  left: 50%;
  transform: translateX(-50%);
}

.milestone__date {
  max-width: 15rem;
  margin-left: auto;
  margin-right: auto;
}
</style>
