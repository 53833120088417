import { format } from 'date-fns';
import { nl } from 'date-fns/locale';

const locales = { nl };

export default function (date, formatStr = 'PP') {
  return format(date, formatStr, {
    // eslint-disable-next-line no-underscore-dangle
    locale: locales[window.__localeId__],
  });
}
